import '@hotwired/turbo-rails'
import 'bootstrap'
import { Toast, Modal } from 'bootstrap'

const schnelleStelle = {}

schnelleStelle.slideGallery = function (items, minPerSlide) {
  items.forEach((item) => {
    let next = item.nextElementSibling

    for (var i = 1; i < minPerSlide; i++) {
      if (!next) {
        // wrap carousel by using first child
        next = items[0]
      }

      let cloneChild = next.cloneNode(true)
      item.appendChild(cloneChild.children[0])
      next = next.nextElementSibling
    }
  })
}

schnelleStelle.filterContent = function (e) {
  let term = e.target.value.toLowerCase()

  if (e.key === 'Enter') {
    e.target.blur()
  }

  if (e.key === 'Escape') {
    term = ''
    e.target.value = ''
  }

  const parentNodes = []

  document.querySelectorAll('[data-filter-content]').forEach(el => {
    if (term === '' || el.dataset.filterContent.includes(term)) {
      el.classList.remove('d-none')
    } else {
      el.classList.add('d-none')
    }

    if (parentNodes.includes(el.parentNode) === false) {
      parentNodes.push(el.parentNode)
    }
  })

  parentNodes.forEach(parentNode => {
    let filterResults = parentNode.children.length
    for (let i = 0; i < parentNode.children.length; i ++) {
      if (parentNode.children[i].classList.contains('d-none')) {
        filterResults--
      }
    }

    parentNode.dataset.filterResults = filterResults

    if (parentNode.previousElementSibling) {
      parentNode.previousElementSibling.dataset.followingFilterResults = filterResults
    }
  })
}

schnelleStelle.filterTagged = function (e) {
  document.querySelectorAll('[data-labels]').forEach(el => {
    if (el.dataset.labels.split(',').includes(e.target.dataset.showLabel)) {
      el.classList.remove('d-none')
    } else {
      el.classList.add('d-none')
    }
  })
}

schnelleStelle.subnavigation = {
  open: (event) => {
    if (window.outerWidth < 992) {
      event.target.nextElementSibling.classList.toggle('active')
    }
  },
  close: (event) => {
    event.target.parentElement.parentElement.classList.remove('active')
  }
}

schnelleStelle.toggleVideo = (video, playButton) => {
  // Event listener for the play/pause button
  if (video.paused == true) {
    // Play the video
    video.play();

    // Update the button text to 'Pause'
    playButton.classList.add('pause')
    playButton.classList.remove('play')
  } else {
    // Pause the video
    video.pause();

    // Update the button text to 'Play'
    playButton.classList.add('play')
    playButton.classList.remove('pause')
  }
}

function jobInput(event) {
  const value = event.target.value

  if (value.length <= 2) return

  fetch(`${coreUrl}/isco/occupations?search=${value}*`)
    .then(response => response.json())
    .then(json => {
        const jobList = document.getElementById('job_list')
        jobList.innerHTML = ''

        json.forEach(value => {
          const option = document.createElement('option')
          option.value = value.label_de
          jobList.appendChild(option)
        })
    })
}

function placeInput(event) {
  const value = event.target.value
  if (value.length <= 2) return

  fetch(`${location.origin}/de/package_assistant?place=${value}`, { headers: { Accept: 'text/html' }})
    .then(response => response.json())
    .then(json => {
      const placeList = document.getElementById('place_list')
      placeList.innerHTML = ''

      json.forEach(value => {
        const option = document.createElement('option')
        option.value = value
        placeList.appendChild(option)
      })
    })
}

function targetBlankForExternalLinks() {
  var links = document.getElementsByTagName('a')

  for (var i = 0; i < links.length; i++) {
    if (/^http/.test(links[i].getAttribute('href'))) {
      links[i].setAttribute('target', '_blank')
      links[i].setAttribute('rel', 'noopener noreferrer')
    }
  }
}

function setupRapidMail() {
  window.RMForm = null
  document.querySelectorAll("#rmOrganism + style").forEach(el => el.remove());
  document.querySelectorAll("#rmOrganism").forEach(el => el.remove());

  let wrapper = document.querySelector("#popup-script-wrapper");
  if (!wrapper) {
    wrapper = document.createElement("div");
    wrapper.id = "popup-script-wrapper";
    document.body.appendChild(wrapper);
  }

  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = "https://t0af9e223.emailsys1a.net/form/71/8906/e796dcd24d/popup.js?_g=1713793884";

  wrapper.innerHTML = "";
  wrapper.appendChild(script);

  document.querySelectorAll(".rm-open-popup").forEach(el => {
    el.addEventListener("click", ev => {
      ev.preventDefault();
      const popup = document.querySelector(".rmPopup__container");
      popup.classList.add("rmPopup--show");
    });
  });
}

function setupPopup() {
  // see _popup.html.erb
  document.querySelectorAll('[data-popup]').forEach(popup => {
    let closeButton = popup.querySelector('[data-close]')
    closeButton.addEventListener("click", function() {
      popup.style.display = 'none'
    })

    setTimeout(() => {
      popup.style.display = 'none'
    }, 10000)
  })
}

function setupDataToggle() {
  const visibilityThreshold = -20

  document.querySelectorAll('[data-toggle-products]').forEach(trigger => {
    const el = trigger.parentElement.parentElement
    trigger.parentElement.onclick = () => {
      if (el.classList.contains('partially-expanded')) {
        el.classList.remove('partially-expanded')
        el.querySelectorAll('.self-expanded').forEach(product => {
          product.classList.remove('self-expanded')
        })
        el.classList.remove('expanded');
      } else {
        el.classList.toggle('expanded');
      }

      return false
    }
  })

  document.querySelectorAll('.partially-expandable').forEach(list => {
    const itemSelector = '[data-toggle-product]'
    list.querySelectorAll(itemSelector).forEach(trigger => {
      trigger.onclick = () => {
        trigger.classList.toggle('self-expanded');

        if (list.querySelector('.self-expanded')) {
          list.classList.add('partially-expanded')
        } else {
          list.classList.remove('partially-expanded')
        }

        return false
      }
    })
  })
}

function openTargetedAccordion() {
  const targetId = window.location.hash
  if (!targetId) { return }

  const target = document.querySelector(`.accordion-item${targetId} .accordion-button.collapsed`)
  if (!target) { return }

  target.click()
}

function showToasts() {
  [...document.querySelectorAll(".toast")].forEach(toastEl => new Toast(toastEl, { autohide: false }).show());
  [...document.querySelectorAll(".modal")].forEach(modalEl => new Modal(modalEl, {}).show());
}

schnelleStelle.prefillEmailForRapidmail = function (email) {
  const popupField = document.querySelector('#rmOrganism input[name=email]')
  if (popupField) {
    popupField.value = email
  }
}

document.querySelectorAll("[data-request-product]").forEach(trigger => {
  trigger.onclick = () => {
    const field = document.getElementById("message_product")
    if (field) {
      field.value = trigger.dataset.requestProduct
    }
  }
})

function init() {
  targetBlankForExternalLinks()
  setupRapidMail()
  setupPopup()
  openTargetedAccordion()
  showToasts()
}

function update() {
  setupDataToggle()
  showToasts()
}

window.schnelleStelle = schnelleStelle

window.onload = init
document.documentElement.addEventListener('turbo:load', init)
document.documentElement.addEventListener("turbo:frame-load", update)
